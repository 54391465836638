.tag-woman {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  padding: 0.25rem;
  background-color: #ff7bca;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
}

.tags-container {
  width: 100%;
  gap: 0.25rem;
  bottom: 0;
  padding: 0.5rem;
  position: absolute;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.tag-ig {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  padding: 0.25rem;
  background-color: #358467;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
}

.tag-certificate {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  padding: 0.25rem;
  background-color: #f7ba00;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
}

.tag-young {
  gap: 0.25rem;
  width: 100%;
  display: flex;
  padding: 0.25rem;
  background-color: #30aae0;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
}

.tag-text-color {
  color: white;
}
