.section-background-new {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2.5rem 0;
  height: auto;
}

@media (max-width: 991px) {
  .section-background-new {
    padding: 1rem 0;
  }
}

.title-event {
  color: white;
  font-size: clamp(1rem, 5vw, 4rem);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .title-event {
  color: white;
  font-size: 3rem;
  font-weight: bold;
} */

.section-container {
  padding: 64px 0px;
  position: relative;
}

.section-content {
  height: 100%;
  display: flex;
  gap: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-sic-a {
  margin: 0px;
}

.text-sic {
  margin: 0px;
}

@media (max-width: 768px) {
  .text-sic {
    display: none;
  }
}
@media (max-width: 768px) {
  .section-content {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
}

.logo-container {
  border-radius: 5px;
  background: #ed433d;
  width: 211px;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .section-content {
    width: 100%;
  }
}

.logo-image {
  width: 150px;
  height: auto;
}

.text-box {
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  width: 450px;
  padding: 32px;
}

@media (max-width: 768px) {
  .text-box {
    width: 100%;
  }
}

.finalists-section {
  background: #773a26;
  width: 100%;
  height: auto;
  padding: 64px 0px;
}

.finalists-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

@media (max-width: 768px) {
  .finalists-title {
    text-align: center;
  }
}

.lots-section {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  padding: 64px 0px;
}

.lots-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3a3938 !important;
}

@media (max-width: 768px) {
  .lots-title {
    text-align: center;
  }
}

.card-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 768px) {
  .card-grid {
    display: flex;
    overflow: visible;
    overflow-y: auto;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card-grid-a {
  width: 100%;
  display: grid;
  gap: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 768px) {
  .card-grid-a {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .card-grid-a {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .card-grid-a {
    grid-template-columns: repeat(4, 1fr);
  }
}

.filters-box {
  flex-direction: row;
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  background-color: white;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 768px) {
  .filters-box {
    padding-top: 2rem;
    flex-direction: column;
  }
}

.filters-labels {
  color: #212529;
}

.filters-row {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.filters-slider {
  width: 100%;
  padding: 0 1rem;
}
