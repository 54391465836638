.card-galeria {
  background-color: white;
  padding: 32px;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.card-galeria-scroll {
  width: 100%;
  overflow: visible;
  overflow-x: auto;
}

.master-container {
  background-color: #f8f9fa;
}

.new-card-producer {
  background-color: white;
  padding: 32px;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 16px;
}

.new-section-container {
  padding: 32px 0px;
  background-color: #f8f9fa;
}

.new-back-container {
  padding-top: 32px;
}

.new-galeria-container {
  padding: 0px;
  width: 100%;
  background-color: #f8f9fa;
}

.new-producer-name {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #3a3938;
}

.new-title-producer {
  color: #3a3938;
}

.new-producer-image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.new-producer-details {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
}

.new-farm-name {
  font-size: 16px;
  color: #74788d;
}

.new-location-info {
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #356b531a;
  padding: 4px 12px;
  border-radius: 5px;
}

.new-info-icons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.new-info-icon-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.new-info-icon {
  width: 15px;
  height: 15px;
}
.new-lots-section {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  padding: 32px 0px;
}
