.image-offer {
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
}

.headerAuctionDetails {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.contente-lot-details-header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.box-lot-details-header {
  width: 50%;
  min-width: 50%;
}

@media (max-width: 1000px) {
  .contente-lot-details-header {
    flex-direction: column;
    margin-bottom: 0;
  }
  .box-lot-details-header {
    width: 100%;
    min-width: 50%;
  }
}

.row-offer {
  gap: 1rem;
  margin: 0rem;
  display: flex;
  margin-bottom: 2rem;
  flex-direction: row;
}
.content-info {
  gap: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.card-info {
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.card-info-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.row-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.col-info {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.text-info-1 {
  font-size: 1rem;
}

.text-info-2 {
  font-size: 1rem;
  font-weight: bold;
  color: #3b5534;
  text-align: right;
}

@media (max-width: 414px) {
  .text-info-1,
  .text-info-2 {
    font-size: 0.75rem;
  }
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.specie::first-letter {
  text-transform: uppercase;
}
