.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.loading-box {
  display: flex;
  gap: 0.25rem;
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loading-text {
  font-size: 0.875rem;
}
