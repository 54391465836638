.contentTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.scoreBox,
.speciesBox {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: #356b5333;
  padding: 8px;
  border-radius: 4px;
  flex-shrink: 0;
}

.preparationBox {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  background-color: #356b5333;
  padding: 8px;
  border-radius: 4px;
  max-width: 100%;
}

.textScore,
.textSpecies {
  color: #356b53;
  white-space: nowrap;
}

.textPreparation {
  color: #356b53;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 991px) {
  .textPreparation {
    text-overflow: ellipsis;
    max-width: 150px;
  }
}
