@media screen and (max-width: 768px) {
  .mobile-display {
    display: block !important;
    opacity: 1 !important;
  }
}

.auction-live-card {
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.5rem;
  overflow: hidden;
}

.auction-card-image {
  height: 16rem;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .auction-card-image {
    height: 8rem;
  }
}

.auction-favorite-button {
  position: absolute;
  margin: 0.5rem;
  right: 0;
}

.auction-favorite-icon {
  width: 28px;
  height: 28px;
}

.auction-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  height: 100%;
}

.auction-classification-info {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .auction-classification-info {
    flex-direction: column;
  }
}

.auction-classification-badge {
  background-color: #356b531a;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

@media (max-width: 768px) {
  .auction-classification-badge {
    padding: 0.25rem;
  }
}

.auction-classification-icon {
  width: 20px;
  height: 20px;
}

.auction-classification-text {
  color: #356b53;
}

.auction-producer-info {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.auction-producer-title {
  color: #212529;
  font-size: 1.5rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .auction-producer-title {
    text-align: center;
    font-size: 1.3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.auction-producer-subtitle {
  color: #6c757d;
  font-size: 1.25rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .auction-producer-subtitle {
    font-size: 1rem;
    text-align: center;
  }
}

.auction-info-icons {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .auction-info-icons {
    flex-direction: column;
  }
}

.auction-info-icon-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.auction-info-icon {
  width: 20px;
  height: 20px;
}
