.component-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 0rem !important;
  padding: 1.5rem;
  border: none !important;
  justify-content: space-between;
}

.component-buttonSave {
  margin: 0px;
  border-radius: 4px;
  border-width: 0rem;
  padding: 1rem 2rem;
  height: 40px;
  background-color: #3b5534;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.component-buttonSave:disabled {
  opacity: 0.4;
  background-color: gray;
}

.component-buttonSave:hover {
  opacity: 0.4;
}

.component-buttonCancel {
  margin: 0px;
  padding: 1rem 2rem;
  border-radius: 4px;
  height: 40px;
  border-width: 0rem;
  background-color: #f1f3f9;
  color: #3a3938;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component-buttonCancel:hover {
  opacity: 0.4;
}
