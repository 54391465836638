.card-producer {
  background-color: white;
  padding: 32px;
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .card-producer {
    width: 300px;
  }
}

.producer-image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.producer-details {
  display: flex;
  gap: 0.5rem;
  overflow: hidden;
  flex-direction: row;
}

@media (max-width: 768px) {
  .producer-details {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.text-producer {
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media (max-width: 768px) {
  .text-producer {
    text-align: center;
  }
}

.producer-name {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #3a3938;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .producer-name {
    font-size: 20px;
  }
}

.farm-name {
  font-size: 16px;
  color: #74788d;
}

.location-info {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #356b531a;
  padding: 4px 12px;
  border-radius: 5px;
}

.info-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .info-icons {
    align-items: center;
    justify-content: center;
  }
}

.info-icon-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info-icon {
  width: 15px;
  height: 15px;
}
