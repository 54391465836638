.component-modal-header {
  height: 4rem;
  width: 100%;
  background-color: #f1f3f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  padding: 1.5rem;
}

.component-modal-title {
  font-size: 1.5rem;
  color: #3a3938;
}

.component-modal-close-button {
  position: absolute;
  right: 0;
  padding-right: 1rem;
}
.component-icon-modal {
  color: #3a3938;
}
