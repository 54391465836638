.section-background {
  width: 100%;
  background-size: cover;
  background-position: center;
}

.section-container {
  padding: 64px 0px;
  position: relative;
}

.section-content {
  height: 100%;
  display: flex;
  gap: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-sic-a {
  margin: 0px;
}

.text-sic {
  margin: 0px;
}

@media (max-width: 768px) {
  .text-sic {
    display: none;
  }
}
@media (max-width: 768px) {
  .section-content {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
}

.logo-container {
  border-radius: 5px;
  background: #ed433d;
  width: 211px;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .section-content {
    width: 100%;
  }
}

.logo-image {
  width: 150px;
  height: auto;
}

.text-box {
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  width: 450px;
  padding: 32px;
}

@media (max-width: 768px) {
  .text-box {
    width: 100%;
  }
}
.event-ATeG-c {
  display: flex;
  width: 100%;
  height: 300px;
  background-color: #4cb95e;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  padding: 3rem;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.event-ATeG-c:hover {
  opacity: 0.5;
  color: white;
  transform: scale(1.1);
}

.event-ATeG-a {
  display: flex;
  width: 100%;
  height: 300px;
  background-color: #0a6251;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  padding: 3rem;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.event-ATeG-a:hover {
  opacity: 0.5;
  color: white;
  transform: scale(1.1);
}

.event-MCSF {
  display: flex;
  width: 100%;
  height: 300px;
  background-color: #ed433d;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  padding: 3rem;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.event-MCSF:hover {
  opacity: 0.5;
  color: white;
  transform: scale(1.1);
}

.event-CNA {
  display: flex;
  width: 100%;
  height: 300px;
  background-color: #f7ba00;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  padding: 3rem;
  color: white;
  cursor: pointer;
}

.event-CNA:hover {
  opacity: 0.5;
  color: white;
  transform: scale(1.1);
}

.content-evets {
  display: grid;
  gap: 2rem;
  padding: 80px 0px;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 768px) {
  .content-evets {
    gap: 1rem;
    padding: 32px 0px;
    grid-template-columns: repeat(1, 1fr);
  }
}
