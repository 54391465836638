.container-events {
  border-radius: 20px !important;
  overflow: hidden !important;
  cursor: pointer !important;
}
.container-events:hover {
  opacity: 0.2 !important;
}
.content-events {
  padding: 64px 0px;
}

@media (max-width: 767px) {
  .content-events {
    padding: 32px;
  }
}

.content-testimonial {
  padding-bottom: 80px;
}

@media (max-width: 767px) {
  .content-testimonial {
    padding: 0px 32px;
    padding-bottom: 32px;
  }
}
