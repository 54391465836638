.boxRating {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #356b5333;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.textRating {
  font-size: 20px;
  font-weight: bold;
  color: #356b53;
  white-space: nowrap;
}
.contentModalRating {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

.alertModal {
  width: 100%;
  justify-items: center;
  align-items: center;
  display: flex;
}
.colModalRating {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}
