.container-isa {
  width: 100%;
  min-width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: row;
}

.content-box-imagens-isa {
  gap: 1rem;
  width: 30%;
  display: grid;
  overflow: hidden;
  max-height: 350px;
  overflow-y: auto;
  direction: rtl;
  grid-template-rows: repeat(3, 1fr);
}

.box-a-isa {
  max-width: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #88888826;
  max-height: 350px;
  overflow: hidden;
  border-radius: 5px;
}

.box-a-isa-empty-state {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f1f3;
  max-height: 350px;
  overflow: hidden;
  border-radius: 5px;
}

.button-isa {
  background-color: #88888826;
  width: 100%;
  height: 80px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  border-radius: 5px;
  border: none;
}

.button-isa:hover {
  opacity: 0.5;
}

.image-isa {
  width: auto;
  height: 100%;
}
