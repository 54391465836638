.component-modal-container {
  overflow: auto;
}

.component-modal-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none !important;
}

.component-modal-subtitle {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.125rem;
}

.component-modal-content {
  width: 100%;
  border: none !important;
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5rem;
}
