.button-download {
  padding: 0px;
  border: none;
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.button-download:hover {
  opacity: 0.4;
}

.button-download:disabled {
  opacity: 0.4;
}

.box-button-download {
  background-color: #3b5534;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}
.box-text-button-download {
  padding: 0rem 1rem;
  height: 60px;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.title-button-download {
  font-size: 1rem;
  font-weight: bold;
}

.subtitle-button-download {
  font-size: 14px;
  line-height: 12px;
}
