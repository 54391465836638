@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./assets/css/animate.css');
@import url('./assets/css/all.css');
@import url('./assets/css/bootstrap.min.css');
@import url('./assets/css/boxicons.min.css');
@import url('./assets/css/bootstrap-icons.css');
@import url('./assets/css/swiper-bundle.min.css');
@import url('./assets/css/slick-theme.css');
@import url('./assets/css/slick.css');
@import url('./assets/css/nice-select.css');
@import url('./assets/css/magnific-popup.css');
@import url('./assets/css/odometer.css');
@import url('./assets/style.css');
@media only screen and (min-width: 1199px) {
  .main-menu ul li:hover ul.submenu {
    display: block !important;
    transition: all linear 0.3s;
  }
}
.select {
  position: relative;
  width: 80px;
}

body {
  overflow-x: hidden;
  font-family: 'Saira', sans-serif;
}

.link-agroger:hover {
  opacity: 0.4;
}
.shadow-standard {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
}
.mb-8 {
  margin-bottom: 2rem;
}

.select.style-2 {
  position: relative;
  width: 100%;
  border: 1px solid #eee;
  height: 50px;
  line-height: 30px;
  border-radius: 5px;
}

.chart-radar {
  width: 75%;
  max-height: 200px;
}

.select.style-2 .selectBtn {
  font-size: 13px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #696969;
}
.footer-row {
  gap: 2rem;
  display: flex;
  flex-direction: row;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.hide-scrollbar::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 6px !important;
  height: 6px !important;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #88888850 !important;
  border-radius: 20px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

@media (max-width: 767px) {
  .footer-row {
    display: flex;
    gap: 0;
    flex-direction: column;
  }
}

.footer-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  text-align: center;
}

.footer-faemg {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .footer-item a {
    display: flex;
    justify-content: center;
  }

  .footer-logo img {
    width: 60%;
  }
}

.select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Saira', sans-serif;
  color: #696969;
}

.select .selectBtn:after {
  content: '';
  position: absolute;
  top: 45%;
  right: 20px;
  width: 7px;
  height: 7px;
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #696969;
  border-bottom: 2px solid #696969;
  transition: 0.2s ease;
}

.select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}

.select .selectBtn.toggle:after {
  transform: translateY(-50%) rotate(-135deg);
}

.select .selectDropdown {
  position: absolute;
  top: 40px;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;
  transform: scale(1, 0);
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.select .selectDropdown .option {
  padding: 8px 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Saira', sans-serif;
  color: #696969;
}

.select .selectDropdown .option:hover {
  background: #5ecc76;
  color: #fff;
}

.select .selectDropdown.toggle {
  visibility: visible;
  transform: scale(1, 1);
} /*# sourceMappingURL=index.css.map */

input[type='date']:required:invalid::-webkit-datetime-edit,
input[type='date']::-webkit-datetime-edit {
  color: transparent;
}
input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}

button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

.image-cell {
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100%;
  border: none;
}

.image-cell img {
  overflow: hidden;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.producer-avatar {
  width: 100px;
  height: 100px;
  background-color: #356b53;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  text-transform: uppercase;
}

.scoreContainer {
  display: flex;
  justify-content: space-between;
}

.scoreContainer h1 {
  text-align: right;
}

.img-medalha {
  width: 100px !important;
  height: auto !important;
}

.button-registration-form {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: #3b5534;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px;
  outline: none;
  border: 1px solid #3b5534;
  border-radius: 5px;
  transition: 0.4s ease;
}

.button-registration-form:hover {
  background: #fff;
  color: #3b5534;
}
